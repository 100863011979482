import {
  FaExclamationTriangle,
  FaPaw,
  FaStar,
  FaThumbsUp,
} from "react-icons/fa";
import {
  SiInstagram,
  SiTwitter,
  SiTiktok,
  SiFacebook,
  SiYoutube,
  SiTelegram,
  SiLinkedin,
  SiSpotify,
  SiWhatsapp,
  SiVk,
  SiTwitch,
} from "react-icons/si";
export const iconMap = {
  "New Services": { icon: FaStar, color: "yellow.400" },
  Recommended: { icon: FaThumbsUp, color: "green.400" },
  "Telegram PREMIUM Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM Members (Targeted)": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram PREMIUM Channel Boost": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram PREMIUM BOT Start": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Targeted Members": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Post Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Views - NEW and OLD Posts": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Targeted Views With Statistics / AUTO Views": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Targeted Views From Search / AUTO Views": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Post Promotion (Statistics Views + Reaction + Comment + Share)": {
    icon: SiTelegram,
    color: "#0088cc",
  },
  "Telegram Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Premium Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Reactions": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Story Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Comments": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Post Share + Targeted": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Telesco.pe Views": { icon: SiTelegram, color: "#0088cc" },
  "Telegram BOT Start": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Comments / Shares": { icon: SiTelegram, color: "#0088cc" },
  "Telegram Auto Views Per Minute": { icon: SiTelegram, color: "#0088cc" },
  "Instagram Followers [No Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers [Guaranteed]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers [Targeted]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Followers BIG Packages": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [No Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [Refill]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Likes [Targeted]": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Views": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Save / Story / Impressions / Reach / Profile Visit": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram Comments": { icon: SiInstagram, color: "#E1306C" },
  IGTV: { icon: SiInstagram, color: "#E1306C" },
  "Instagram Reel": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Mentions": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Live Video": { icon: SiInstagram, color: "#E1306C" },
  "Instagram VERIFIED Services": { icon: SiInstagram, color: "#E1306C" },
  "Instagram VERIFIED Services Turkey": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram VERIFIED Services EGYPT": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  "Instagram Auto Likes": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Likes Targeted": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Views": { icon: SiInstagram, color: "#E1306C" },
  "Instagram Auto Comments / Save / Story / Impressions": {
    icon: SiInstagram,
    color: "#E1306C",
  },
  Threads: { icon: SiInstagram, color: "#E1306C" }, // Assuming Threads icon is same as Instagram
  "Youtube Watchtime [WORK AFTER UPDATE]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Subscribers [No refill]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Subscribers [Guaranteed]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Shorts": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [No Refill]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Guaranteed]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Targeted]": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Views [Mix / Targeted From ADS]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Views [RAV™ - GS Unique][Language - Targeted]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Views [RAV™- Unique][GEO - Targeted]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Likes / DisLikes [No Refill]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Likes / DisLikes  [Guaranteed]": {
    icon: SiYoutube,
    color: "#FF0000",
  },
  "Youtube Shorts Views / Likes": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Comments Mix / Target": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Live Stream / Premiere": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Social Shares": { icon: SiYoutube, color: "#FF0000" },
  "Youtube Shares": { icon: SiYoutube, color: "#FF0000" },
  "Facebook Followers / Friends / Group members": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Followers / Friends / Group members Targeted": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Page Likes": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Live Stream Views": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Video Views": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Post Likes / Stories / Shares / Events": {
    icon: SiFacebook,
    color: "#1877F2",
  },
  "Facebook Post Likes Targeted": { icon: SiFacebook, color: "#1877F2" },
  "Facebook Post Comments": { icon: SiFacebook, color: "#1877F2" },
  "TikTok Followers": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Views": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Likes / Shares / Comments": { icon: SiTiktok, color: "#69C9D0" },
  "TikTok Livestream Views": { icon: SiTiktok, color: "#69C9D0" },
  "VK.com": { icon: SiVk, color: "#4C75A3" },
  Twitch: { icon: SiTwitch, color: "#9146FF" },
  "Twitter NFT Services": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Followers": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Retweets": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Likes": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Clicks": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Impressions": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Poll Votes": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Views / Comments": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Live / Space Listeners": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter Data Scraper": { icon: SiTwitter, color: "#1DA1F2" },
  "Twitter USA Follower Services": { icon: SiTwitter, color: "#1DA1F2" },
  LinkedIn: { icon: SiLinkedin, color: "#0077B5" },
  "Spotify Free Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Premium Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Followers": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Podcast Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Saves": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Monthly Listeners": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Search Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Playlist Plays": { icon: SiSpotify, color: "#1DB954" },
  "Spotify Playlist Followers": { icon: SiSpotify, color: "#1DB954" },
  "WhatsApp 📌NEW": { icon: SiWhatsapp, color: "#25D366" },
  "⏺ TEST [Do not order]": { icon: FaExclamationTriangle, color: "red.500" },
  "Hamster Kombat": { icon: FaPaw, color: "brown.500" },
};

export const excludeIds = [
  
];

export const excludedServiceCategories = [
];
